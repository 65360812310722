<template>
    <section id="content">

        <div class="content-wrap py-0 overflow-visible">
            <div class="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-5 mb-lg-0">
                <div class="card-body p-5">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="heading-block border-bottom-0 mb-0">
                                <div class="badge badge-pill badge-default">Our experience in the mobility</div>
                                <p>To create a solid foundation for mobility and stimulate the growth of your organization, take an advantage of our wide range of mobility services and solutions.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src="../../assets/demos/seo/images/services/adwords.svg" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container clearfix">
                <div class="row col-mb-50 mb-0">
                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box fbox-effect">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-check-circle i-alt"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3>Use the advantages of your mobility</h3>
                                <p>Increase your labor and business productivity, optimize the workflows, and attract your customers with mobility.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box fbox-effect">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-check-circle i-alt"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3>Provide your customers with the excellent service.</h3>
                                <p>Increase and attract your customers on any device and at any time with our secure mobility offerings.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box fbox-effect">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-check-circle i-alt"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3>Increase the productivity of your employees</h3>
                                <p>Improve the staff work with the help of the mobile applications which are a necessary part of the business, to facilitate the staff work, to protect the company data and to optimize the costs.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box fbox-effect">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-check-circle i-alt"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3>Watch out for the tasks performing</h3>
                                <p>The optimizing, the reports maintaining, the business messages and the obligations performed by your employees are to increase the sales revenue.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider divider-sm divider-center"><i class="icon-crop"></i></div>
            <div class="section mb-0">
            <div class="container clearfix">
                <div class="heading-block border-bottom-0 center">
                    <div class="badge badge-pill badge-default">Transform your business with the help of our individual mobile solutions and services!</div>
                </div>
                <div class="row col-mb-30 align-content-stretch">

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/seo.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">UI / UX</h3>
                                        <p class="text-smaller">To achieve the set goals and results in the development of a mobile application, a good design has a crucial significance, regardless of whether it is a B2C or B2E application, and our design team understands it well. The years of experience and the qualifications of our designers allow to create the exact design which corresponds to the direction of the application, focused on the end users (customers, partners or employees).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/social.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">Application development</h3>
                                        <p class="text-smaller">Our development team will create an application for you using the latest technologies in the field of information technology and development that will provide the expected result for the business. Our many years of experience give a clear understanding of which technology platform to choose for each individual development (native cross-platform or hybrid) to integrate your application.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/group.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">Testing, maintenance and support.</h3>
                                        <p class="text-smaller">To ensure the high standards of the application quality, we thoroughly test every application we create, starting from the convenience in use till performance. To speed up the time for your application market entry, we use the newest testing platforms.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/analysis.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">Back-End implementation and Integration</h3>
                                        <p class="text-smaller">For the smooth deployment and operation of the corporate applications, it is often necessary to work seamlessly with other Saas and local applications used for the various purposes. That is why we offer complex services for the integration of corporate applications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/experience.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">Mobile Applications Analytics
                                        </h3>
                                        <p class="text-smaller">To determine the indicators of the application efficiency, the user interaction, the revenue matrices and the redirection, we develop the specific analytical solutions for mobile applications. This allows you to collect, analyze and consolidate the mobile applications data.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="feature-box flex-column">
                                    <div class="fbox-icon mb-3">
                                        <img src="../../assets/demos/seo/images/icons/content_marketing.svg" alt="Feature Icon" class="bg-transparent rounded-0">
                                    </div>
                                    <div class="fbox-content">
                                        <h3 class="nott ls0 text-larger">Modernization and refinement of the obsolete applications</h3>
                                        <p class="text-smaller">With the time, previously created applications become obsolete and terminate to function properly. It is connected with the new technologies release, the method of the code writing, the release of new operating systems for mobile devices. Our team can perform an in-depth audit of the existing outdated mobile applications, update and do everything necessary to breathe a new life and make the mobile application even more efficient.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            <div class="divider divider-sm divider-center"><i class="icon-crop"></i></div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>

    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Mobility",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Mobility',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },

        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>

</style>